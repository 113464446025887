import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(async (request) => {
  const authToken = localStorage.getItem('auth_token');
  if (authToken) request.headers['x-auth-token'] = authToken;

  return request;
});

export default api;
