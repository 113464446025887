import { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import classes from './style';
import dayjs from 'dayjs';
import api from '../../services/apiClient';
import {
  bloodTypeOptions,
  countryOptions,
  genderRange,
  getInitialParticipantState,
  idTypeCats,
  relationOptions,
} from '../../controllers/DetailParticipants';
import { FormTextField } from '..';

const DetailParticipants = ({
  eventId,
  showDialog,
  closeDialog,
  goToSummary,
  kidsLimit,
  adultsLimit,
  kidMaxAge,
  participantLimit,
  adultMinAge,
  adultMaxAge,
  kidSizeRange,
  adultSizeRange,
  maxAllowedDate,
  isAllSos,
  isGroup,
  includeJersey,
  sizeChartUrl,
  categoryId,
  sizesInfo = [],
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [participants, setParticipants] = useState([]);
  const [email, setEmail] = useState({
    email: {
      value: '',
      errorMessage: '',
    },
  });
  const [team, setTeam] = useState({
    name: {
      value: '',
      errorMessage: '',
    },
  });
  const [emergency, setEmergency] = useState({
    person: { value: '', errorMessage: '' },
    contact: { value: '', errorMessage: '' },
    relation: { value: '', errorMessage: '' },
  });
  const [checkLimitLoad, setCheckLimitLoad] = useState(false);

  const [currentPage, setCurrentPage] = useState();
  const [showSizeChart, setShowSizeChart] = useState(false);
  useEffect(() => {
    //   console.log(`kidsLimit : ${kidsLimit},
    // adultsLimit : ${adultsLimit},
    // kidMaxAge : ${kidMaxAge},
    // participantLimit : ${participantLimit},
    // adultMinAge : ${adultMinAge},
    // kidSizeRange : ${kidSizeRange},
    // adultSizeRange : ${adultSizeRange},
    // isAllSos : ${isAllSos},
    // isGroup : ${isGroup},
    // includeJersey : ${includeJersey}`);

    const list = getInitialParticipantState(
      undefined,
      isAllSos,
      kidsLimit,
      adultsLimit,
      participantLimit,
      kidSizeRange,
      adultSizeRange,
      sizesInfo
    );
    setParticipants(list);
    setEmergency({
      person: { value: '', errorMessage: '' },
      contact: { value: '', errorMessage: '' },
      relation: { value: '', errorMessage: '' },
    });
    setTeam({
      name: {
        value: '',
        errorMessage: '',
      },
    });
    setEmail({
      email: {
        value: '',
        errorMessage: '',
      },
    });
  }, [
    isAllSos,
    isGroup,
    kidsLimit,
    adultsLimit,
    participantLimit,
    kidSizeRange,
    adultSizeRange,
    sizesInfo,
  ]);

  useEffect(() => {
    if (!showDialog) return;
    const list = getInitialParticipantState(
      undefined,
      isAllSos,
      kidsLimit,
      adultsLimit,
      participantLimit,
      kidSizeRange,
      adultSizeRange,
      sizesInfo
    );
    setParticipants(list);
    setEmergency({
      person: { value: '', errorMessage: '' },
      contact: { value: '', errorMessage: '' },
      relation: { value: '', errorMessage: '' },
    });
    setTeam({
      name: {
        value: '',
        errorMessage: '',
      },
    });
    setEmail({
      email: {
        value: '',
        errorMessage: '',
      },
    });
    setCurrentPage(0);
  }, [showDialog]);

  const handleDataChange = (value, key, index) => {
    // console.log(value);
    // console.log(key);
    // console.log(index);

    const updatedDP = [...participants];

    if (!!updatedDP[index][key]?.errorMessage)
      updatedDP[index][key].errorMessage = '';

    updatedDP[index][key].value = value;
    setParticipants(updatedDP);
  };

  const handleEmergencyDataChange = (value, key) => {
    const updatedEmergency = { ...emergency };

    if (!!updatedEmergency[key]?.errorMessage)
      updatedEmergency[key].errorMessage = '';

    updatedEmergency[key].value = value;

    setEmergency(updatedEmergency);
  };

  const handleTeamNameChange = (value, key) => {
    const updated = { ...team };
    if (!!updated[key]?.errorMessage) updated[key].errorMessage = '';
    updated[key].value = value;
    setTeam(updated);
  };

  const handleEmailChange = (value, key) => {
    const updated = { ...email };
    if (!!updated[key]?.errorMessage) updated[key].errorMessage = '';
    updated[key].value = value;
    setEmail(updated);
  };

  const handleCloseDialog = () => {
    // setTeamName({
    //   value: '',
    //   errorMessage: '',
    // });

    // setEmergency({
    //   person: { value: '', errorMessage: '' },
    //   contact: { value: '', errorMessage: '' },
    //   relation: { value: '', errorMessage: '' },
    // });

    // const list = getInitialParticipantState(
    //   { firstName: 'hartz', lastName: 'Lie' },
    //   false,
    //   0,
    //   2,
    //   0,
    //   '1,7',
    //   '1,7'
    // );
    // setParticipants(list);

    closeDialog();
  };

  const handleBack = () => {
    if (currentPage === 3 && isAllSos > 0) return setCurrentPage(1);

    if (currentPage === 0) return handleCloseDialog();

    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = async () => {
    const { valid, errMsg } = await isDataValid();
    if (valid === false) return alert(errMsg);

    //if (currentPage === 0) return setCurrentPage(2);
    if (currentPage === 1 && isAllSos > 0) return setCurrentPage(3);
    if (currentPage === 3)
      return goToSummary({
        email,
        team,
        participants,
        emergency,
      });

    setCurrentPage((prev) => prev + 1);
  };

  const getButtonBackText = () => {
    if (currentPage === 0) return 'Close';

    return 'Back';
  };

  const getButtonNextText = () => {
    if (currentPage === 3) return 'Summary';

    return 'Next';
  };

  const isDataValid = async () => {
    let isDataValid = true;
    let errMsg = 'Something Wrong, Please check your form.';

    const teamUpdated = { ...team };
    if (currentPage === 0) {
      if (isGroup > 0) {
        if (team.name?.value === '') {
          teamUpdated.name.errorMessage = 'Team Name is required!';

          setTeam(teamUpdated);
          isDataValid = false;
        } else {
          const teamNameLength = team?.name?.value?.length || 0;
          if (teamNameLength > 15) {
            teamUpdated.name.errorMessage = 'Exceed maximal characters (15)';

            setTeam(teamUpdated);
            isDataValid = false;
          }
        }
      }

      const updated = { ...email };
      if (email.email?.value === '') {
        updated.email.errorMessage = 'Email is required!';

        setEmail(updated);
        isDataValid = false;
      } else {
        const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          email.email?.value
        );
        if (!isEmail) {
          updated.email.errorMessage = 'Email is not valid!';

          setEmail(updated);
          isDataValid = false;
        }
      }
    }

    // if (currentPage === 1) {
    //
    // }

    if (currentPage === 1) {
      const updated = [...participants];
      updated.forEach((p) => {
        if (p.name?.value === '') {
          p.name.errorMessage = 'Name is required!';
          isDataValid = false;
        }

        if (p.gender?.value === '') {
          p.gender.errorMessage = 'Gender is required!';
          isDataValid = false;
        }

        if (p.nationality?.value === '') {
          p.nationality.errorMessage = 'Nationality is required!';
          isDataValid = false;
        }

        if (p.idType?.value === '') {
          p.idType.errorMessage = 'Id Type is required!';
          isDataValid = false;
        }

        if (p.idNumber?.value === '') {
          p.idNumber.errorMessage = 'Id Number is required!';
          isDataValid = false;
        }

        if (p.birthDate?.value !== '') {
          // console.log(p.birthDate.value);
          const aMinAge = parseInt(adultMinAge);
          const aMaxAge = parseInt(adultMaxAge);

          const ageInMonth = dayjs().diff(dayjs(p.birthDate?.value), 'months');
          const age = Math.floor(ageInMonth / 12);
          // console.log(age);

          if (aMinAge > 0 && aMaxAge > 0) {
            if (age < aMinAge || age > aMaxAge) {
              if (age < aMinAge && maxAllowedDate) {
                // console.log('category id IN (146, 148)');
                const bedayUnix = dayjs(p.birthDate?.value).unix();
                // console.log('beday unix ', bedayUnix);
                const limitUnix = dayjs(maxAllowedDate).unix();
                // console.log('limit unix', limitUnix);
                const isBedayValid = bedayUnix <= limitUnix;
                // console.log('isBedayValid ', isBedayValid);
                if (!isBedayValid) {
                  p.birthDate.errorMessage = `Minimum age allowed is ${aMinAge}`;
                  isDataValid = false;
                }
              } else {
                p.birthDate.errorMessage = `Allowed age is between ${aMinAge} and ${aMaxAge}`;
                isDataValid = false;
              }
            }
          } else if (aMinAge > 0) {
            // console.log('minimal age used! ', aMinAge);
            if (age < aMinAge) {
              // console.log('max allowed date ', maxAllowedDate);
              // console.log('cat ID ', categoryId);

              if (maxAllowedDate) {
                // console.log('category id IN (146, 148)');
                const bedayUnix = dayjs(p.birthDate?.value).unix();
                // console.log('beday unix ', bedayUnix);
                const limitUnix = dayjs(maxAllowedDate).unix();
                // console.log('limit unix', limitUnix);
                const isBedayValid = bedayUnix <= limitUnix;
                // console.log('isBedayValid ', isBedayValid);
                if (!isBedayValid) {
                  p.birthDate.errorMessage = `Minimum age allowed is ${aMinAge}`;
                  isDataValid = false;
                }
              } else {
                console.log('category id not valid');
                p.birthDate.errorMessage = `Minimum age allowed is ${aMinAge}`;
                isDataValid = false;
              }
            }
          }
        }

        if (p.birthDate?.value === '') {
          p.birthDate.errorMessage = 'Birthdate is required!';
          isDataValid = false;
        }

        if (p.bloodType?.value === '') {
          p.bloodType.errorMessage = 'Blood Type is required!';
          isDataValid = false;
        }

        if (p.contact?.value === '') {
          p.contact.errorMessage = 'Phone Number is required!';
          isDataValid = false;
        }

        if (includeJersey > 0 && p.size?.value === '') {
          p.size.errorMessage = 'Size is required!';
          isDataValid = false;
        }

        if (isAllSos > 0 && p.emergencyPerson?.value === '') {
          p.emergencyPerson.errorMessage = 'Emergency Person is Required!';
          isDataValid = false;
        }

        if (isAllSos > 0 && p.emergencyContact?.value === '') {
          p.emergencyContact.errorMessage = 'Emergency Contact is Required!';
          isDataValid = false;
        }

        if (isAllSos > 0 && p.emergencyRelation?.value === '') {
          p.emergencyRelation.errorMessage = 'Emergency Relation is Required!';
          isDataValid = false;
        }
      });

      setParticipants(updated);

      if (isDataValid === true) {
        const { valid, alertMessage } = await isSizeLimitValid();
        if (valid === false) {
          isDataValid = false;
          errMsg = alertMessage;
        }
      }
    }

    if (currentPage === 2) {
      const updated = { ...emergency };
      if (emergency.person?.value === '') {
        updated.person.errorMessage = 'Person Name is Required!';
        isDataValid = false;
      }

      if (emergency.contact?.value === '') {
        updated.contact.errorMessage = 'Person Contact is Required!';
        isDataValid = false;
      }

      if (emergency.relation?.value === '') {
        updated.relation.errorMessage = 'Person Relation is Required!';
        isDataValid = false;
      }

      setEmergency(updated);
    }

    return { valid: isDataValid, errMsg };
  };

  const isSizeLimitValid = async () => {
    if (sizesInfo.length === 0) return { valid: true, alertMessage: 'ok' };

    const data = [...participants];

    const sizes = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      const isFoundIdx = sizes.findIndex(
        (s) => s?.size === element?.size?.value
      );

      // console.log('is size limit valid, isFound ', isFoundIdx);

      if (isFoundIdx < 0) {
        sizes.push({ size: element.size.value, sizeCount: 1 });
      } else {
        sizes[isFoundIdx].sizeCount++;
      }
    }
    // console.log('is size limit valid ', sizes);
    try {
      setCheckLimitLoad(true);
      const { data } = await api.post('/events/size/limit', {
        eventId,
        sizes,
      });

      setCheckLimitLoad(false);
      // console.log(result);
      if (data.isValid === false)
        return { valid: false, alertMessage: data.message };

      return { valid: true, alertMessage: 'ok' };
    } catch (error) {
      setCheckLimitLoad(false);
      return { valid: false, alertMessage: error.message };
    }
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: !fullScreen ? '768px' : '100%', // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {'Add detail Ticket'}
        </DialogTitle>
        <DialogContent>
          {currentPage === 0 && (
            <Grid container style={classes.containerSpaceAround}>
              <Grid item xs={12} sm={6}>
                <DialogContentText align="center">Email</DialogContentText>
                <Box
                  component={'form'}
                  noValidate
                  autoComplete="off"
                  sx={{ m: 1 }}
                >
                  {/* email */}
                  <FormTextField
                    index={40}
                    i={0}
                    required
                    label={'Email'}
                    errorMessage={email?.email?.errorMessage}
                    value={email?.email?.value}
                    onChange={(e) =>
                      handleEmailChange(e.target?.value, 'email')
                    }
                  />
                  <Typography
                    variant="subtitle2"
                    color={'CaptionText'}
                    sx={{ marginTop: '-10px', marginBottom: '20px' }}
                  >
                    Pastikan Alamat Email ditulis dengan benar, untuk menerima
                    Email Konfirmasi Pembayaran / E Ticket. Sebagai bukti
                    pengambilan Rackit yang Sah saat RPC berlangsung.
                  </Typography>

                  {/* team name */}
                  {isGroup > 0 && (
                    <>
                      <FormTextField
                        index={41}
                        i={0}
                        required
                        label={'Team Name'}
                        errorMessage={team?.name?.errorMessage}
                        value={team?.name?.value}
                        onChange={(e) =>
                          handleTeamNameChange(e.target?.value, 'name')
                        }
                      />

                      <Typography
                        variant="subtitle2"
                        color={'CaptionText'}
                        sx={{ marginTop: '-10px' }}
                      >
                        Isi Nama Group/Team dengan benar, karna akan tercetak
                        pada BIB anda. (Max 15 character)
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}

          {/* {currentPage === 1 && (
            <Grid container style={classes.containerSpaceAround}>
              <Grid item xs={12} sm={6}>
                <DialogContentText align="center">Team</DialogContentText>
                <Box
                  component={'form'}
                  noValidate
                  autoComplete="off"
                  sx={{ m: 1 }}
                ></Box>
              </Grid>
            </Grid>
          )} */}

          {currentPage === 1 && (
            <Grid container style={classes.containerSpaceAround}>
              {participants.map((p, idx) => (
                <Grid key={idx} item xs={12} sm={6}>
                  <DialogContentText align="center">
                    Runner {idx + 1}
                  </DialogContentText>
                  <Box
                    component={'form'}
                    noValidate
                    autoComplete="off"
                    sx={{ m: 1 }}
                  >
                    {/* name */}
                    <FormTextField
                      index={0}
                      i={idx}
                      required
                      label={'Name'}
                      errorMessage={p.name?.errorMessage}
                      value={p.name?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'name', idx)
                      }
                    />
                    {/* gender */}
                    <FormTextField
                      index={1}
                      i={idx}
                      required
                      type={'select'}
                      label={'Gender'}
                      selectOpts={genderRange}
                      errorMessage={p.gender?.errorMessage}
                      value={p.gender?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'gender', idx)
                      }
                    />
                    {/* nationality */}
                    <FormTextField
                      index={2}
                      i={idx}
                      required
                      type={'select'}
                      label={'Nationality'}
                      selectOpts={countryOptions}
                      errorMessage={p.nationality?.errorMessage}
                      value={p.nationality?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'nationality', idx)
                      }
                    />
                    {/* id type */}
                    <FormTextField
                      index={3}
                      i={idx}
                      required
                      type={'select'}
                      label={'Id Type'}
                      selectOpts={idTypeCats}
                      errorMessage={p.idType?.errorMessage}
                      value={p.idType?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'idType', idx)
                      }
                    />
                    {/* id number */}
                    <FormTextField
                      index={4}
                      i={idx}
                      required
                      label={'Id Number'}
                      errorMessage={p.idNumber?.errorMessage}
                      value={p.idNumber?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'idNumber', idx)
                      }
                    />
                    {/* birth date */}
                    <FormTextField
                      index={5}
                      i={idx}
                      required
                      type={'date'}
                      label={'Birth Date'}
                      errorMessage={p.birthDate?.errorMessage}
                      value={p.birthDate?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'birthDate', idx)
                      }
                    />
                    {/* blood type */}
                    <FormTextField
                      index={6}
                      i={idx}
                      required
                      type={'select'}
                      label={'Blood Type'}
                      selectOpts={bloodTypeOptions}
                      errorMessage={p.bloodType?.errorMessage}
                      value={p.bloodType?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'bloodType', idx)
                      }
                    />
                    {/* contact */}
                    <FormTextField
                      index={7}
                      i={idx}
                      required
                      label={'Phone Number'}
                      errorMessage={p?.contact?.errorMessage}
                      value={p?.contact?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'contact', idx)
                      }
                    />
                    {/* organization */}
                    <FormTextField
                      index={8}
                      i={idx}
                      label={'Organization / Community (opt)'}
                      errorMessage={p.orgCom?.errorMessage}
                      value={p.orgCom?.value}
                      onChange={(e) =>
                        handleDataChange(e.target?.value, 'orgCom', idx)
                      }
                    />
                    {/* size */}
                    {includeJersey > 0 && (
                      <>
                        {sizeChartUrl && (
                          <Button
                            sx={{ paddingTop: 0, marginTop: '-5px' }}
                            onClick={() => setShowSizeChart((prev) => !prev)}
                          >
                            Size Chart
                          </Button>
                        )}
                        <FormTextField
                          index={9}
                          i={idx}
                          required
                          type={'select'}
                          label={'Size'}
                          selectOpts={p?.sizeRange}
                          errorMessage={p.size?.errorMessage}
                          value={p.size?.value}
                          onChange={(e) =>
                            handleDataChange(e.target?.value, 'size', idx)
                          }
                        />
                      </>
                    )}

                    {isAllSos > 0 && (
                      <>
                        {/* name */}
                        <FormTextField
                          index={10}
                          i={idx}
                          required
                          label={'Emergency Person'}
                          errorMessage={p?.emergencyPerson?.errorMessage}
                          value={p?.emergencyPerson?.value}
                          onChange={(e) =>
                            handleDataChange(
                              e.target?.value,
                              'emergencyPerson',
                              idx
                            )
                          }
                        />

                        {/* contact */}
                        <FormTextField
                          index={11}
                          i={idx}
                          required
                          label={'Emergency Phone Number'}
                          errorMessage={p?.emergencyContact?.errorMessage}
                          value={p?.emergencyContact?.value}
                          onChange={(e) =>
                            handleDataChange(
                              e.target?.value,
                              'emergencyContact',
                              idx
                            )
                          }
                        />
                        {/* Relation */}
                        <FormTextField
                          index={12}
                          i={idx}
                          required
                          type={'select'}
                          label={'Emergency Relation'}
                          selectOpts={relationOptions}
                          errorMessage={p?.emergencyRelation?.errorMessage}
                          value={p?.emergencyRelation?.value}
                          onChange={(e) =>
                            handleDataChange(
                              e.target?.value,
                              'emergencyRelation',
                              idx
                            )
                          }
                        />
                      </>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}

          {currentPage === 2 && (
            <Grid container style={classes.containerSpaceAround}>
              <Grid item xs={12} sm={6}>
                <DialogContentText align="center">
                  Emergency Person
                </DialogContentText>
                <Box
                  component={'form'}
                  noValidate
                  autoComplete="off"
                  sx={{ m: 1 }}
                >
                  {/* name */}
                  <FormTextField
                    index={31}
                    i={0}
                    required
                    label={'Name'}
                    errorMessage={emergency?.person?.errorMessage}
                    value={emergency?.person?.value}
                    onChange={(e) =>
                      handleEmergencyDataChange(e.target?.value, 'person')
                    }
                  />

                  {/* contact */}
                  <FormTextField
                    index={32}
                    i={0}
                    required
                    label={'Phone Number'}
                    errorMessage={emergency?.contact?.errorMessage}
                    value={emergency?.contact?.value}
                    onChange={(e) =>
                      handleEmergencyDataChange(e.target?.value, 'contact')
                    }
                  />
                  {/* Relation */}
                  <FormTextField
                    index={33}
                    i={0}
                    required
                    type={'select'}
                    label={'Relation'}
                    selectOpts={relationOptions}
                    errorMessage={emergency?.relation?.errorMessage}
                    value={emergency?.relation?.value}
                    onChange={(e) =>
                      handleEmergencyDataChange(e.target?.value, 'relation')
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          {currentPage === 3 && (
            <DialogContentText>
              Is the data correct? Make sure the data you enter is correct. Your
              data will be reset after leaving this page.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleBack} disabled={checkLimitLoad}>
            {getButtonBackText()}
          </Button>
          <LoadingButton
            onClick={handleNext}
            autoFocus
            loading={checkLimitLoad}
          >
            {getButtonNextText()}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={showSizeChart}>
        <img src={sizeChartUrl} alt={'size-chart'} />
        <DialogActions>
          <Button onClick={() => setShowSizeChart((prev) => !prev)}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DetailParticipants;
