// order_id=RCE2988125241072566570&status_code=201&transaction_status=pending
// 21, 455

import { Link, useSearchParams } from 'react-router-dom';
import { useGetOrderInfoQuery } from '../../services/iracedb';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import classes from './style';
import { toReadableDateTimeString } from '../../utils/dateFormatter';
import { toCurrencyForm } from '../../utils/stringFormatter';
import { Error } from '@mui/icons-material';

const PageData = ({ label, text }) => (
  <Box display={'flex'} sx={{ paddingInline: '20px', mb: 1 }}>
    <Typography
      variant="h6"
      sx={{ flexGrow: 1, fontWeight: '600' }}
      color={'GrayText'}
    >
      {label}
    </Typography>
    <Typography variant="body1">{text}</Typography>
  </Box>
);

const PaymentFinish = () => {
  const [searchParams] = useSearchParams();
  const { data, isFetching, error } = useGetOrderInfoQuery(
    searchParams.get('order_id')
  );

  if (!!!searchParams.get('order_id'))
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        justifyContent={'center'}
        alignContent={'center'}
        marginTop={'4rem'}
      >
        <Typography variant="h2" color={'GrayText'} marginTop={'4rem'}>
          No Order Assign
        </Typography>
      </Box>
    );

  if (error)
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        justifyContent={'center'}
        alignContent={'center'}
        marginTop={'4rem'}
      >
        <Error sx={{ fontSize: '64px', color: '#afafaf' }} />
        <Typography sx={{ color: '#afafaf' }} variant="h3">
          {error.error.split(':')[1].toUpperCase()}
        </Typography>
      </Box>
    );

  if (isFetching)
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        justifyContent={'center'}
        alignContent={'center'}
        marginTop={'4rem'}
      >
        <CircularProgress size={'4rem'} />
      </Box>
    );

  return (
    <Grid
      container
      style={classes.containerSpaceAround}
      sx={{ padding: { xs: '2rem', sm: 0 } }}
    >
      <Grid item xs={12} sm={8} md={6}>
        <Card>
          <Box display={'flex'}>
            <CardHeader title={`Order `} sx={{ flexGrow: 1 }} />
            <CardHeader title={`${data?.info?.orderId}`} />
          </Box>
          <Divider sx={{ mb: 1, marginInline: '20px' }} />
          <PageData label={'Event'} text={data?.info?.eventName} />
          <PageData label={'Category'} text={data?.info?.cateogryName} />
          <PageData label={'Sport'} text={data?.info?.sportName} />{' '}
          <PageData
            label={data?.info?.totalTicket > 1 ? 'Participants' : 'Participant'}
            text={data?.info?.totalTicket}
          />
          <Divider sx={{ mb: 1, marginInline: '20px' }} />
          <PageData
            label={'Order Date'}
            text={toReadableDateTimeString(data?.info?.orderDate)}
          />
          <PageData
            label={'Payment Method'}
            text={data?.info?.payMethod.replace('_', ' ')}
          />
          {data?.info?.payMethod === 'bank_transfer' && (
            <PageData label={'Bank'} text={data?.info?.payment.bank} />
          )}
          {data?.info?.payMethod === 'bank_transfer' && (
            <PageData
              label={'Account Number'}
              text={data?.info?.payment.vaNumber}
            />
          )}
          <PageData
            label={'Total Bill'}
            text={`Rp ${toCurrencyForm(data?.info?.orderBill)}`}
          />
          <PageData
            label={'Status'}
            text={
              data?.info?.paymentStatus === 'pending'
                ? 'Waiting payment'
                : data?.info?.paymentStatus
            }
          />
          <Divider sx={{ mb: 1, marginInline: '20px' }} />
          {data?.info?.orderType === 'web' && (
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                LinkComponent={Link}
                to="/"
                variant="contained"
                sx={{
                  width: '90%',
                  marginBottom:
                    data?.info?.paymentStatus === 'pending' ? '0' : '20px',
                }}
              >
                Back To Homepage
              </Button>
            </Box>
          )}
          {data?.info?.paymentStatus === 'pending' && (
            <Typography
              variant="subtitle2"
              gutterBottom
              color={'GrayText'}
              sx={{ paddingInline: 2, marginTop: '10px' }}
            >
              *Please compelete your transaction to join the event and enjoy the
              excitement.
            </Typography>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentFinish;
