import { Error } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AddDetailParticipantDialog,
  CountdownTimer,
  EventInfoFragment,
  FormTextField,
  RaceOrRacepackInfo,
  RacepacksFragments,
  TicketSummaryDialog,
} from '..';
import { LoadingButton } from '@mui/lab';
import api from '../../services/apiClient';
import { useGetEventInformationQuery } from '../../services/iracedb';
import { read } from '../../utils/generateToken';
import { toCurrencyForm } from '../../utils/stringFormatter';
import CustomTabPanel, { a11lyProps } from '../CustomTabPanel/CustomTabPanel';
import classes from './style';

const EventInformation = () => {
  // console.log('Event Information');
  //#region variable, state, initial value
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:1000px)');

  const { id } = useParams();

  const { data, isFetching, error } = useGetEventInformationQuery(read(id));
  // console.log(data);
  // console.log(featuredRcp);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);

  const [tabIdx, setTabIdx] = useState(0);
  const [dp, setDp] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [loadOrderAndGetSnapToken, setLoadOrderAndGetSnapToken] =
    useState(false);
  const [isFetchingCodeStatus, setIsFetchingCodeStatus] = useState(false);
  const [snapToken, setSnapToken] = useState('');
  const [chartUrl, setChartUrl] = useState();
  const [code, setCode] = useState({ value: '', errorMessage: '', data });
  //#endregion

  //#region useeffect
  useEffect(() => {
    if (snapToken === '') return;

    window.location.href = snapToken;
  }, [snapToken]);

  useEffect(() => {
    getSizeChartUrl();
  }, [data?.racepacks]);
  //#endregion

  //#region function
  const getSizeChartUrl = () => {
    if (!data?.racepacks.length === 0) return;

    const sizeChart = data?.racepacks?.find((rcp) =>
      rcp.name.includes('Chart')
    );

    setChartUrl(sizeChart?.image);
  };

  //#region handle code
  const handleCodeDataChange = (key, item) => {
    const updated = { ...code };

    updated[key] = item;

    setCode(updated);
  };
  //#endregion

  const handleJoinEvent = async () => {
    //return console.log('join event ', selectedCategory);
    try {
      if (code.value === '') return alert('Input Code.');
      setIsFetchingCodeStatus(true);

      const { data: couponData } = await api.get(
        `/events/offline/coupon/${data?.info?.eventId}/${code.value}/${selectedCategory?.categoryId}`
      );

      setIsFetchingCodeStatus(false);

      handleCodeDataChange('data', couponData);

      setIsOpenDialog(true);
    } catch (error) {
      setIsFetchingCodeStatus(false);
      alert(error.response.data);
    }
  };

  const handleOnChange = (event) => {
    const categoryFind = data?.categories?.find(
      (cat) => cat.categoryId.toString() === event.target.value.toString()
    );
    // console.log(categoryFind);
    setSelectedCategory(categoryFind);
  };

  const handleTabChange = (event, newValue) => {
    setTabIdx(newValue);
  };

  const handleGoToSummary = ({ email, team, participants, emergency }) => {
    const updated = { ...dp };
    updated.order = {
      email: { value: email.email.value },
      orderType: { value: 'web' },
      bill: { value: 0 },
    };

    updated.team = team;
    updated.participants = participants;
    updated.emergency = emergency;

    setDp(updated);
    setIsOpenDialog((prev) => !prev);
    setSnapToken('');

    setTimeout(() => {
      setIsOpenSummary((prev) => !prev);
    }, 200);
  };

  // const isSizeLimitValid = async () => {
  // if (data?.sizesInfo?.length === 0)
  //   return { valid: true, alertMessage: 'ok' };

  // const data = [...participants];

  // const sizes = [];

  // for (let index = 0; index < data.length; index++) {
  //   const element = data[index];

  //   const isFoundIdx = sizes.findIndex(
  //     (s) => s?.size === element?.size?.value
  //   );

  //   // console.log('is size limit valid, isFound ', isFoundIdx);

  //   if (isFoundIdx < 0) {
  //     sizes.push({ size: element.size.value, sizeCount: 1 });
  //   } else {
  //     sizes[isFoundIdx].sizeCount++;
  //   }
  // }
  // // console.log('is size limit valid ', sizes);
  // try {
  //   setCheckLimitLoad(true);
  //   const { data } = await api.post('/events/size/limit', {
  //     eventId,
  //     sizes,
  //   });

  //   setCheckLimitLoad(false);
  //   // console.log(result);
  //   if (data.isValid === false)
  //     return { valid: false, alertMessage: data.message };

  //   return { valid: true, alertMessage: 'ok' };
  // } catch (error) {
  //   setCheckLimitLoad(false);
  //   return { valid: false, alertMessage: error.message };
  // }
  // };

  const handleChoosePayment = async ({ bill, coupon }) => {
    // return console.log(data);

    // if (data?.info?.eventId === 45 && !coupon?.data?.coupon_id)
    //   return alert('You can continue after use coupon');

    if (parseInt(bill) === 0) return registerOfflineEvent({ bill, coupon });

    if (loadOrderAndGetSnapToken) return;
    if (snapToken !== '') return;

    setLoadOrderAndGetSnapToken(true);

    const params = { ...dp };
    params.order.bill.value = bill;

    const participantUpdated = params.participants.map((p) => {
      const localDateString = dayjs(p.birthDate.value).format('MM/DD/YYYY');
      return {
        ...p,
        birthDate: localDateString,
      };
    });
    params.participants = participantUpdated;

    // return console.log(
    //   JSON.stringify({
    //     event: data?.info,
    //     category: selectedCategory,
    //     coupon: {
    //       id: coupon?.data?.coupon_id,
    //       code: coupon?.data?.code,
    //     },
    //     ...params,
    //   })
    // );

    try {
      const result = await api.post('/events/offline/web2', {
        event: data?.info,
        category: selectedCategory,
        coupon: {
          id: coupon?.data?.coupon_id,
          code: coupon?.data?.code,
        },
        ...params,
      });

      //console.log('HERE ', result);
      // alert(`Check your offlane races table ${result.data}`);
      setSnapToken(result.data.url);
      // setTimeout(() => {
      //   setLoadOrderAndGetSnapToken(false);
      // }, 200);
    } catch (error) {
      setLoadOrderAndGetSnapToken(false);
      setSnapToken('');
      alert(JSON.stringify(error.response.data));
    }
  };

  const registerOfflineEvent = async ({ bill, coupon }) => {
    // console.log('register offline event!');

    // if (data?.info?.eventId === 45 && !coupon?.data?.coupon_id)
    //   return alert('You can continue after use coupon');

    const params = { ...dp };
    params.order.bill.value = bill;

    const participantUpdated = params.participants.map((p) => {
      const localDateString = dayjs(p.birthDate.value).format('MM/DD/YYYY');
      return {
        ...p,
        birthDate: localDateString,
      };
    });
    params.participants = participantUpdated;
    // console.log('register offline event free');
    // console.log(' coupon from TS ', coupon);
    // console.log('coupon code ', code);
    const cp = code?.data?.coupon_id ? code : coupon;
    // console.log(cp);
    // console.log({
    const regParams = {
      event: data?.info,
      category: selectedCategory,
      coupon: {
        id: cp?.data?.coupon_id,
        code: cp?.data?.code,
      },
      ...params,
    };

    // return console.log(regParams);

    setLoadOrderAndGetSnapToken(true);

    try {
      await api.post('/events/offline/web2/free', regParams);

      //console.log('HERE ', result);
      // alert(`Check your offlane races table ${result.data}`);
      alert(`Registration Complete, E-ticket will be sent via email`);

      window.location = '/';
      setTimeout(() => {
        setLoadOrderAndGetSnapToken(false);
      }, 150);
    } catch (error) {
      setLoadOrderAndGetSnapToken(false);
      alert(JSON.stringify(error.response.data));
    }
  };

  const getRemainingSlotText = (item) => {
    if (!item) return '-';

    const limit = parseInt(item.priceLimit);
    // console.log(limit);
    const priceCount =
      parseInt(item.pricePending) + parseInt(item.priceSuccess);
    // console.log(priceCount);
    const resultCount = limit - priceCount;
    // console.log('result count ', resultCount);

    if (resultCount > 0) {
      const dividerNumber =
        parseInt(item.adultsLimit) +
        parseInt(item.kidsLimit) +
        parseInt(item.participantLimit);
      // console.log('divider number ', dividerNumber);

      return resultCount / dividerNumber;
    } else {
      const pending = parseInt(item.pricePending);
      if (pending > 0) return 'Full';
      return 'Sold Out!';
    }
  };

  const checkSlotLimit = (item) => {
    if (!item) return;

    const limit = parseInt(item.priceLimit);
    // console.log(limit);
    const priceCount =
      parseInt(item.pricePending) + parseInt(item.priceSuccess);
    // console.log(priceCount);
    const resultCount = limit - priceCount;
    // console.log('result count ', resultCount);

    if (resultCount > 0) {
      // console.log('divider number ', dividerNumber);

      return true;
    } else {
      const pending = parseInt(item.pricePending);
      if (pending > 0) alert('Slot Full, please try again later');
      else alert('Ticket Sold Out!');

      return false;
    }
  };

  const joinEvent = (item) => {
    const valid = checkSlotLimit(item);

    if (valid === false) return;

    setIsOpenDialog((prev) => !prev);
  };
  //#endregion

  //#region loading and error view
  if (isFetching)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        marginTop={'2rem'}
      >
        <CircularProgress size={'4rem'} />
      </Box>
    );

  if (error)
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        sx={{ height: '500px' }}
      >
        <Error sx={{ fontSize: '64px', color: '#afafaf' }} />
        <Typography sx={{ color: '#afafaf' }} variant="h3">
          {error.error.split(':')[1].toUpperCase()}
        </Typography>
      </Box>
    );
  //#endregion

  return (
    <>
      <Grid
        container
        style={classes.containerSpaceAround}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          flexWrap: { xs: 'wrap', sm: 'inherit' },
        }}
      >
        {/* image */}
        <Grid item sm={12} lg={5} style={classes.bannerContainer}>
          <img
            src={data?.info?.banner}
            alt={data?.info?.name}
            style={
              isMobile
                ? classes.bannerMobile
                : isTab
                ? classes.bannerMedium
                : classes.banner
            }
          />
        </Grid>
        {/* event detail information container */}
        <Grid
          item
          container
          lg={7}
          sx={{
            marginTop: { xs: '0', sm: '2rem', lg: '0' },
            paddingInline: { xs: '2em', sm: '0' },
            paddingBottom: { xs: '' },
          }}
        >
          {/* event information detail */}
          <Grid item xs={12} sm={6}>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              {/* event info -- start */}
              <Typography variant={!isMobile ? 'h4' : 'h6'} fontWeight={'600'}>
                {data?.info?.name}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabIdx}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Details" {...a11lyProps(0)} />
                    <Tab label="Racepacks" {...a11lyProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabIdx} index={0}>
                  {/* event info -- end */}
                  <RaceOrRacepackInfo
                    isMobile={isMobile}
                    label={'Race Info'}
                    location={data?.info?.eventLocation}
                    start={data?.info?.startDate}
                    end={data?.info?.endDate}
                  />
                  {/* racepack info -- start */}
                  <RaceOrRacepackInfo
                    isMobile={isMobile}
                    label={'Racepack Collection Info'}
                    location={data?.info?.racepackCollection}
                    start={data?.info?.startRacepackCollectionDate}
                    end={data?.info?.endRacepackCollectionDate}
                    isRacepack
                  />

                  {/* description */}
                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Description'}
                    body={data?.info?.description}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Race Organizer'}
                    body={data?.info?.organizer || '-'}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Race Management'}
                    body={data?.info?.raceManagement || '-'}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Timing System'}
                    body={data?.info?.timingSystem || '-'}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabIdx} index={1}>
                  <RacepacksFragments racepacks={data?.racepacks} />
                </CustomTabPanel>
              </Box>
            </div>
          </Grid>

          {/* choose category */}
          <Grid item xs={12} sm={6}>
            <div
              style={{
                display: 'flex',
                marginLeft: isMobile ? '0' : '1rem',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                marginBottom={'10px'}
                flexDirection={'column'}
                borderRadius={'14px'}
                padding={'10px 16px'}
                flex={1}
                sx={{
                  border: 3,
                  borderColor: 'grey.200',
                }}
              >
                {dayjs().unix() <
                  dayjs(data?.info?.startRegistrationDate).unix() && (
                  <CountdownTimer
                    openRegDate={data?.info?.startRegistrationDate}
                  />
                )}

                {dayjs().unix() >
                  dayjs(data?.info?.endRegistrationDate).unix() && (
                  <Typography variant="h4" color={'GrayText'}>
                    Registration Already Closed.
                  </Typography>
                )}

                {dayjs().unix() >=
                  dayjs(data?.info?.startRegistrationDate).unix() &&
                dayjs().unix() <=
                  dayjs(data?.info?.endRegistrationDate).unix() ? (
                  <FormControl>
                    <FormLabel id="choose-category-form">
                      Choose Your Category
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="choose-category-form"
                      value={selectedCategory?.categoryId}
                      onChange={handleOnChange}
                    >
                      {data?.categories?.map((category, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={category?.categoryId}
                          control={<Radio />}
                          label={category?.name}
                        />
                      ))}
                    </RadioGroup>
                    {selectedCategory && (
                      <>
                        {data?.info?.isInternal === 0 && (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Divider style={{ marginBottom: '4px' }} />
                            <Box display={'flex'} flexDirection={'row'}>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Registration Fee
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.registrationFee === '0'
                                    ? 'FREE'
                                    : `Rp
                                  ${toCurrencyForm(
                                    selectedCategory?.registrationFee
                                  )}`}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Price Group
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.priceLabel}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Slot
                                </Typography>
                                <Typography variant="body1">
                                  {getRemainingSlotText(selectedCategory)}
                                </Typography>
                              </Box>
                            </Box>

                            <Button
                              variant="contained"
                              style={{ marginTop: '10px' }}
                              onClick={() => joinEvent(selectedCategory)}
                            >
                              Join Event!
                            </Button>
                          </Box>
                        )}
                        {data?.info?.isInternal > 0 && (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Divider style={{ marginBottom: '4px' }} />
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              marginBottom={'20px'}
                            >
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  RegistrationFee
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.registrationFee === '0'
                                    ? 'FREE'
                                    : `Rp
                                  ${toCurrencyForm(
                                    selectedCategory?.registrationFee
                                  )}`}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Price Group
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.priceLabel}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Slot
                                </Typography>
                                <Typography variant="body1">
                                  {getRemainingSlotText(selectedCategory)}
                                </Typography>
                              </Box>
                            </Box>

                            <FormTextField
                              index={1}
                              i={0}
                              required
                              label={'Code'}
                              errorMessage={code.errorMessage}
                              value={code.value}
                              onChange={(e) =>
                                handleCodeDataChange('value', e.target.value)
                              }
                            />

                            <LoadingButton
                              variant="contained"
                              onClick={handleJoinEvent}
                              loading={isFetchingCodeStatus}
                            >
                              Join Event!
                            </LoadingButton>
                          </Box>
                        )}
                      </>
                    )}
                  </FormControl>
                ) : null}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <AddDetailParticipantDialog
        eventId={data?.info?.eventId}
        showDialog={isOpenDialog}
        closeDialog={() => setIsOpenDialog((prev) => !prev)}
        categoryId={selectedCategory?.categoryId}
        kidsLimit={selectedCategory?.kidsLimit}
        kidMaxAge={selectedCategory?.kidMaxAge}
        kidSizeRange={selectedCategory?.kidSizeIndex}
        adultsLimit={selectedCategory?.adultsLimit}
        adultMinAge={selectedCategory?.adultMinAge}
        adultMaxAge={selectedCategory?.adultMaxAge}
        maxAllowedDate={selectedCategory?.maxAllowedDate}
        adultSizeRange={selectedCategory?.adultSizeIndex}
        participantLimit={selectedCategory?.participantLimit}
        includeJersey={selectedCategory?.includeJersey}
        isAllSos={selectedCategory?.isAllSos}
        isGroup={selectedCategory?.isGroup}
        goToSummary={handleGoToSummary}
        sizeChartUrl={chartUrl}
        sizesInfo={data?.sizesInfo}
      />
      <TicketSummaryDialog
        showSummary={isOpenSummary}
        onClose={() => setIsOpenSummary((prev) => !prev)}
        event={data?.info}
        team={dp?.team}
        participants={dp?.participants}
        emergency={dp?.emergency}
        category={selectedCategory}
        order={dp?.order}
        onChoosePayment={handleChoosePayment}
        isLoading={loadOrderAndGetSnapToken}
      />
    </>
  );
};

export default EventInformation;
